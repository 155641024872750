import React, { useEffect, useState } from "react";
import "./App.css";
import About from "./components/About";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Gallery from "./components/Gallery";
import Main from "./components/Main";
import Menu from "./components/Menu";
import Navigation from "./components/Navigation";
import { useDispatch, useSelector } from "react-redux";
import { getStoreInfo, loadApi } from "./redux/api/actions";
import { Spinner } from "react-bootstrap";

function App() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const centricoApi = useSelector((state) => state.apiReducer);
  console.log(centricoApi)
  const getInfo = async () => {
    let results = await getStoreInfo().then((response) => {
      dispatch(loadApi(response.data));
      setLoading(false);
    });
  };

  useEffect(() => {
    getInfo();
  }, []);

  return loading === true ? (
    <div className="loading">
      <Spinner animation="border" role="status" variant="dark" />
    </div>
  ) : (
    <div className="App">
      <Navigation {...centricoApi.store} />
      <Main {...centricoApi} />
      <About {...centricoApi.store} />
      <Menu {...centricoApi} />
      <Gallery {...centricoApi.galleryCategories[0]} />
      <Contact {...centricoApi.store} />
      <Footer {...centricoApi} />
    </div>
  );
}

export default App;
