import React from "react";
import bitesImage from "../images/about/bitesImageRound.png";
import cocktailsImage from "../images/about/cocktailsImageRound.png";
const About = (store) => {
  return (
    <div className="about" id="about">
      <div className="aboutImage">
        <div className="container-fluid aboutText text-end">
          <div className="row aboutRow">
            <div className="col-lg-6 col-xs-12">{/*placeholder*/}</div>
            <div className="col-lg-6 col-xs-12">
              <h1 className="aboutHeading">About Centrico</h1>
              <p className="aboutParagraph" dangerouslySetInnerHTML={{ __html: `${store.about_text}` }} />
              {/* <p className="aboutParagraph">
                Centrico is the newest addition of Bars in Wanchai from Everest
                Group HK. From our unique cocktails to our ever-changing list of
                local spirits, we have everything you need to have a great night
                out.
              </p>
              <p className="aboutParagraph">
                Centrico is the newest addition of Bars in Wanchai from Everest
                Group HK. From our unique cocktails to our ever-changing list of
                local spirits, we have everything you need to have a great night
                out.
              </p> */}
            </div>
          </div>
        </div>
        <div className="menuImageWine">
          <div className="container-fluid" id="service">
            <div className="row">
              <div className="col-md-6 bites">
                <div className="text">
                  <h5>TASTY BITES</h5>
                  <p>
                    You’ll be sure to love our bar snacks. The perfect
                    complement to your cosmic cocktail, our food is created with
                    love in our kitchen and perfect to share.{" "}
                  </p>
                  <p>
                    While the space at The Centrico is out of this world, our
                    cocktails and mocktails will set your phasers to stun. The
                    best part? The Station night Bar has one of the longest
                    happy hours in Wan Chai Hong Kong, starting at 3pm and
                    lasting until 4am every single night.
                  </p>
                  <p>
                    You’ll be sure to love our bar snacks. The perfect
                    complement to your cosmic cocktail, our food is created with
                    love in our kitchen and perfect to share.
                  </p>
                  <div className="bitesImage">
                    <img src={bitesImage} alt="" />
                  </div>
                </div>
              </div>
              <div className="col-md-6 cocktails">
                <div className="text">
                  <h5>COSMIC COCKTAILS</h5>
                  <p>
                    You’ll be sure to love our bar snacks. The perfect
                    complement to your cosmic cocktail, our food is created with
                    love in our kitchen and perfect to share.{" "}
                  </p>
                  <p>
                    While the space at The Centrico is out of this world, our
                    cocktails and mocktails will set your phasers to stun. The
                    best part? The Station night Bar has one of the longest
                    happy hours in Wan Chai Hong Kong, starting at 3pm and
                    lasting until 4am every single night.
                  </p>
                  <p>
                    You’ll be sure to love our bar snacks. The perfect
                    complement to your cosmic cocktail, our food is created with
                    love in our kitchen and perfect to share.
                  </p>
                  <div className="cocktailsImage">
                    <img src={cocktailsImage} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
