import axios from "axios";

export const LOAD_CENTRICO_API_SUCCESS = "LOAD_CENTRICO_API_SUCCESS";
export const LOAD_CENTRICO_API_FAILURE = "LOAD_CENTRICO_API_FAILURE";

export function loadApi(data) {
    return (dispatch) => {
            dispatch({type: LOAD_CENTRICO_API_SUCCESS, data: data})
    }
}

export const getStoreInfo = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_CENTRICO_API)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };