import React from 'react';
import zincLogo from "../images/logo/zincLogo.png";
import { Nav, Navbar, Container } from 'react-bootstrap';

const Navigation = (props) => {
    return props === undefined ? <></> : (
        <>
        <Navbar
          fixed="top"
          className="smallNav"
          bg="transparent"
          variant="dark"
          expand="lg"
        >
          <Container className="navWidth ">
            <Navbar.Toggle aria-controls="basic-navbar-nav text-center" />
              <img src={zincLogo} alt="" width="65" className="navImage m-auto d-lg-none"/>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="m-auto align-items-center">
  
                <Nav.Link href="#home"><img src={process.env.REACT_APP_IMAGE_STORAGE + props.icon_image_path} height="50" alt="" /></Nav.Link>{" "}
                <Nav.Link href="#about">ABOUT</Nav.Link>
                <Nav.Link href="#service">SERVICE</Nav.Link> 
                <Navbar.Brand href="#home" className="d-none d-lg-block">
                  <img
                    src={zincLogo}
                    width="80"
                    className="d-inline-block align-top"
                    alt=""
                  />
                </Navbar.Brand><Nav.Link href="#menu">MENU</Nav.Link>
                <Nav.Link href="#gallery">GALLERY</Nav.Link>
                <Nav.Link href="#contact">CONTACT</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
    );
};

export default Navigation;