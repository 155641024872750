import { LOAD_CENTRICO_API_SUCCESS, LOAD_CENTRICO_API_FAILURE } from "./actions";

let initialState = {};

export function apiReducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_CENTRICO_API_SUCCESS:
            return Object.assign(action.data)
        default:
            return state;
    }
}