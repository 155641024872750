import React from 'react';
import Slider from 'react-slick';

const Gallery = (props) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3.9999,
        slidesToScroll: 1,
        initialSlide: 1,
        arrows: false,
        focusOnSelect: true,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                }
              },
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                }
              },
              {
                breakpoint: 680,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                }
              }
        ]
      };
    return (
        <div className='gallery' id='gallery'>
            <div className="container">
                <h3 className='text-center galleryh3'>Our Gallery</h3>
                <div className="row ourGalleryRow">
                    <Slider {...settings}>
                      {props.galleries.map((gallery) => {
                        return (
                          <div className="galleryImages" key={gallery.id}><img src={process.env.REACT_APP_IMAGE_STORAGE + gallery.image} alt="" className="ourGalleryImage" /></div>
                        )
                      })}
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default Gallery;