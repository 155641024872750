import React from "react";

const Contact = (props) => {
  return props === undefined ? <></> : (
    <div>
      <div className="contact" id="contact">
        <h5 className="text-center " >Contact Us</h5>
        <h1 className="text-center contactSpacing" >Stay with us OR Join us!</h1>
        <div className="container contactContainer">
          <div className="row">
            <div className="contactImageWrap col-lg-6 "><iframe src={props.google_map} width="100%" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe></div>
              <div className="contactTextWrap col-lg-6  d-flex flex-column align-items-start justify-content-center">
                <img src={process.env.REACT_APP_IMAGE_STORAGE + props.icon_image_path} alt="" className="centricoLogo" />
                <p className="location">Location:<br /> {props.address} </p>
                <p className="tel">Tel: {props.phone}</p>
                <p className="openingHours">Opening Hours: {props.working_hour}</p>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
