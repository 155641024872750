import React, { useState, useEffect } from 'react';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

import menuGin from "../images/background/menuGin.png";

const Menu = ({ menus, menuCategories }) => {
  const [tag, setTag] = useState(menuCategories[0].id)
  const [filteredImages, setFilteredImages] = useState(null)
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setFilteredImages(menus.filter(menu => menu.category.id === tag))
}, [tag])

  return menus === undefined ? <></> : (
    <div className="menu" id="menu">
      {/*  */}
      {/* <img src={menuGin} className="menuGin" alt="" /> */}
      <div className="container-fluid menuCategoryContainer">
        <div className="row">
          <h1 className="ourMenus">Our Menus</h1>
          <div className="col-12 menuButtonHandler">
            {
              menuCategories.map((item, index) =>
                <button key={item.id} className={
                  "menuButtons " + `${tag === item.id ? 'active' : ''}`} onClick={() => setTag(item.id)}>{item.name}</button>
              )
            }
            {/* <button className="col-md-2 col-3 menuButtons" value="small" onClick={(e) => menuHandler(e)}>SMALL BITES</button>
                <button className="col-md-2 col-3 menuButtons" value="big" onClick={(e) => menuHandler(e)}>BIG BITES</button>
                <button className="col-md-2 col-3 menuButtons" value="sweet" onClick={(e) => menuHandler(e)}>SWEET BITES</button>
                <button className="col-md-2 col-3 menuButtons" value="cocktail" onClick={(e) => menuHandler(e)}>COCKTAILS</button>
                <button className="col-md-2 col-3 menuButtons" value="wine" onClick={(e) => menuHandler(e)}>WINE</button>
                <button className="col-md-2 col-3 menuButtons" value="beer" onClick={(e) => menuHandler(e)}>BEER</button> */}
          </div>
        </div>
      </div>


      <div className="container-fluid menuItemsContainer">
        <div className="row">
          <div className="menuItem">

            <div className="row justify-content-center">
              {filteredImages != null ? filteredImages.map((menu, index) => (
                <div key={menu.id} className="col-lg-6 col-12 mt-2" onClick={() => {
                  setPhotoIndex(index)
                  setIsOpen(true)
                }
                }>
                  <img className="w-100" src={`${process.env.REACT_APP_IMAGE_STORAGE}${menu.image}`} alt="menuPhoto" />
                  {/*  
                                   <div className="col-12 col-md-6 eachItem d-flex">
                             <div className="col-9 col-md-7">
                                 <div className="s" key={food.id}>
                             <h3 className="foodName">{food.menu_title}</h3>
                            <p className="foodDescription">{food.menu_content}</p>
                           </div>
                             </div>
                             <div className="col-3 s"><h3 className="foodPrice">HKD ${food.price}</h3></div>
                          
                         </div> */}
                </div>

              )) : null}
            </div>

            {isOpen && (
              <Lightbox
                mainSrc={`${process.env.REACT_APP_IMAGE_STORAGE}${filteredImages[photoIndex]['image']}`}
                onImageLoad={() => {
                  window.dispatchEvent(new Event('resize'));
                }}
                nextSrc={`${process.env.REACT_APP_IMAGE_STORAGE}${filteredImages[(photoIndex + 1) % filteredImages.length]['image']}`}
                prevSrc={`${process.env.REACT_APP_IMAGE_STORAGE}${filteredImages[(photoIndex + filteredImages.length - 1) % filteredImages.length]['image']}`}
                onCloseRequest={() => setIsOpen(false)}
                onMovePrevRequest={() =>
                  setPhotoIndex(
                    (photoIndex + filteredImages.length - 1) % filteredImages.length,
                  )
                }
                onMoveNextRequest={() =>
                  setPhotoIndex(
                    (photoIndex + 1) % filteredImages.length,
                  )
                }
                imageCaption={<div style={{ padding: '16px', margin:'auto' }}>{filteredImages[photoIndex]['menu_content']}</div>}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
