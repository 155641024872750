import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWhatsapp,
  faFacebookMessenger,
} from "@fortawesome/free-brands-svg-icons";
import Slider from "react-slick";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const Main = (props) => {
  const [rightColClass, setRightColClass] = useState(true);
  const [showSlides, setShowSlides] = useState(3);

  const imageClick = () => {
    setRightColClass(!rightColClass);
    if (showSlides === 3) {
      setShowSlides(5.5);
    } else {
      setShowSlides(3);
    }
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: showSlides,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    focusOnSelect: true,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: showSlides,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: showSlides,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  return props === undefined ? (
    <></>
  ) : (
    <div className="mainBig" id="home">
      <Carousel
        autoPlay={true}
        infiniteLoop
        showArrows={false}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        className="d-md-none"
      >
        {props.banners.map((banner) => {
          return (
            <div className="cDiv" key={banner.id}>
              <img
                src={
                  process.env.REACT_APP_IMAGE_STORAGE + banner.banner_image_path
                }
                alt=""
                className="d-md-none mdImage"
              />
              <p className="centricoSmall">{banner.banner_main_title}</p>
            </div>
          );
        })}
      </Carousel>
      <Container fluid>
        <Row>
          <Col className="leftCol">
            <img
              src={
                process.env.REACT_APP_IMAGE_STORAGE +
                props.store.icon_image_path
              }
              width="10%"
              alt=""
            />
            <h1 className="mainh1">CENTRICO</h1>

            <p className="fupper" dangerouslySetInnerHTML={{ __html: `${props.store.about_text}` }} />
            {/* <p className="fupper">
              Centrico is the newest addition of Bars in Wanchai from Everest
              Group HK. From our unique cocktails to our ever-changing list of
              local spirits, we have everything you need to have a great night
              out.
            </p>
            <p className="fupper">
              Centrico is the newest addition of Bars in Wanchai from Everest
              Group HK. From our unique cocktails to our ever-changing list of
              local spirits, we have everything you need to have a great night
              out.
            </p> */}
            <p className="underSliderText d-md-none text-end p-2">
              CENTRICO HK - WANCHAI HONG KONG
            </p>
          </Col>
          <Col
            className={
              rightColClass
                ? "rightCol d-none d-md-block"
                : "rightColExpand d-none d-md-block"
            }
          >
            <div className="relativeRight">
              <Slider {...sliderSettings}>
                {props.galleryCategories[1].galleries.map((gallery) => {
                  return (
                    <div
                      className="galleryImages"
                      onClick={() => imageClick()}
                      key={gallery.id}
                    >
                      <img
                        src={
                          process.env.REACT_APP_IMAGE_STORAGE + gallery.image
                        }
                        alt=""
                        className="galleryImage"
                      />
                    </div>
                  );
                })}
              </Slider>
            </div>
            <h4 className={"underSliderText d-none d-md-block"}>
              CENTRICO HK - WANCHAI HONG KONG
            </h4>
          </Col>
        </Row>
      </Container>
      <div className="mainIcons d-flex flex-column">
        <a target="_blank" href={process.env.REACT_APP_WHATSAPP_URL + props.store.whatsapp}>
          <FontAwesomeIcon icon={faWhatsapp} />
        </a>
        <p style={{ color: "white", textAlign: "center" }}>whatsapp</p>
        <a href={props.store.facebook_messenger} target="_blank">
          <FontAwesomeIcon icon={faFacebookMessenger} />
        </a>
        <p style={{ color: "white", textAlign: "center" }}>facebook</p>
      </div>
    </div>
  );
};

export default Main;
