import React from "react";
import { faInstagram, faFacebook } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = (props) => {
  return (
    <div className="footer">
      <div className="bigFooter d-none d-lg-block">
        <div className="container-fluid border-bottom">
          <div className="row">
            <div className="col-3">
              <a href="#"><img src={process.env.REACT_APP_IMAGE_STORAGE + props.store.icon_image_path} alt="" /></a>
            </div>
            <div className="col-9 pt-3 d-flex justify-content-evenly align-items-center footer-top">
              <p className="m-0"><a href="#about">ABOUT</a></p>
              <p className="m-0"><a href="#service">SERVICE</a></p>
              <p className="m-0"><a href="#menu">MENU</a></p>
              <p className="m-0"><a href="#gallery">GALLERY</a></p>
              <p className="m-0"><a href="#contact">CONTACT</a></p>
            </div>
          </div>
        </div>
        <div className="container-fluid underBorder py-3">
          <div className="row">
            <div className="col-4 copyright">
              <p>{props.store.footer_text}</p>
              <p>&copy; Zinc Group all rights reserved</p>
            </div>
            <div className="col-4">
              <div className="container">
                <div className="row titleRow">
                  <h3>Our Venues</h3>
                  {props.venues.map((venue) => {
                    return (
                      <div className="col-6" key={venue.id}><a href={venue.website}>{venue.name}</a></div>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="container">
                <div className="row titleRow">
                  <h3>Stay In Touch</h3>
                  <p>Join Us</p>
                  <p>Subscribe</p>
                  <div>
                  <a href={props.store.ig_link}><FontAwesomeIcon icon={faInstagram} /></a>
                  <a href={props.store.facebook_link}><FontAwesomeIcon icon={faFacebook} /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-lg-none smallFooter text-center">
        <div className="container">
          <div className="row">
            <div className="inTouch">
              <h2>Stay In Touch</h2>
              <p>Join us</p>
              <p className="">Suscribe</p>
              <div className="spacing d-flex justify-content-center">
                <a href={props.store.ig_link}><FontAwesomeIcon icon={faInstagram} /></a>
                <a href={props.store.facebook_link}><FontAwesomeIcon icon={faFacebook} /></a>
              </div>
            </div>
            <div className="venues">
              <div className="row">
                <h2 className="venue">Our Venues</h2>
                {props.venues.map((venue) => {
                    return (
                      <div className="col-6 venueList" key={venue.id}><a href={venue.website}>{venue.name}</a></div>
                    )
                  })}
              </div>
            </div>
            <div className="lastFooter">
              <p>{props.store.footer_text}</p>
              <p>&copy; Zinc Group all rights reserved</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
